import {Vue} from "vue-property-decorator";
import ConstantTool from "@/services/tool/ConstantTool";
import Order, {OrderType} from "@/models/Order";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import SnackbarModule from "@/store/SnackbarModule";
import JsonTool from "@/services/tool/JsonTool";
import axios, {AxiosError} from "axios";
import Response from "@/models/responses/Response";
import User from "@/models/User";
import LogDisplayError from "@/config/errors/LogDisplayError";

export default class OrderService {

    static async getMyOrdersCsv(
        component: Vue, page: number, size: number, userId: number | null, search: string | null,
        orderType: OrderType | null, clientId: number | null, start: string | null, end: string | null
    ) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/@me/orders/csv", {
                params: { page, size, userId, search, orderType, clientId, start, end },
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            const blob = new Blob([ response.data ], { "type" : "text/csv" });
            let link = document.createElement('a')
            // @ts-ignore
            link.href = window.URL.createObjectURL(blob)
            link.download = 'order.csv'
            // @ts-ignore
            link.click()
        } catch(e) {
            console.log(e)
            getModule(SnackbarModule).makeToast("No se han podido obtener los pedidos");
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async getMyOrders2(
        page: number, size: number, userId: number | null, search: string | null,
        orderType: OrderType | null, clientId: number | null, start: string | null, end: string | null
    ) {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/@me/orders", {
                params: { page, size, userId, search, orderType, clientId, start, end },
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            let orders = JsonTool.jsonConvert.deserializeArray(response.data, Order)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: orders, xTotalCount })
        } catch (e) { return Promise.resolve(e) }
    }

    static async getMyOrders(
        component: Vue, orders: Order[], page: number, size: number, userId: number | null, search: string | null,
        orderType: OrderType | null, clientId: number | null, start: string | null, end: string | null
    ) {
        (<any>component).loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/@me/orders", {
                params: { page, size, userId, search, orderType, clientId, start, end },
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            let list = JsonTool.jsonConvert.deserializeArray(response.data, Order)
            orders.splice(0, orders.length)
            list.forEach(p => orders.push(p))
            // @ts-ignore
            component.totalItems = Number(response.headers["x-total-count"]);
            (<any>component).loading = false;
        } catch(e) {
            console.log(e)
            getModule(SnackbarModule).makeToast("No se han podido obtener los pedidos");
            (<any>component).loading = false
        }
    }

    static async getMyOrdersByUser2(
        page: number, size: number, userId: number, search: string | null, orderType: OrderType | null,
        clientId: number | undefined, start: string | null, end: string | null
    ): Promise<Response<Order[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/@me/users/"+ userId +"/orders", {
                params: { page, size, search, orderType, clientId, start, end },
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            const orders = JsonTool.jsonConvert.deserializeArray(response.data, Order)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: orders, xTotalCount })
        } catch (e) { return Promise.reject(e) }
    }

    static async getMyOrdersByUser(
        component: Vue, orders: Order[], page: number, size: number, userId: number, search: string | null,
        orderType: OrderType | null, clientId: number | undefined, start: string | null, end: string | null
    ) {
        (<any>component).loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/@me/users/"+ userId +"/orders", {
                params: { page, size, search, orderType, clientId, start, end },
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            orders.splice(0, orders.length)
            JsonTool.jsonConvert.deserializeArray(response.data, Order).forEach(p => orders.push(p))
            // @ts-ignore
            component.totalItems = Number(response.headers["x-total-count"]);
            (<any>component).loading = false
        } catch(e) {
            console.log(e)
            getModule(SnackbarModule).makeToast("No se han podido obtener los pedidos");
            (<any>component).loading = false
        }
    }

    static async postOrder(component: Vue, order: Order, clientId: number, send: boolean = false) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.post(ConstantTool.BASE_URL + "/api/clients/"+ clientId +"/orders/",
                order, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })

            const orderResponse = JsonTool.jsonConvert.serializeObject(response.data, Order)

            if (send) { await this.sendOrder(component, orderResponse.id!)}
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("Generado un pedido");
        } catch (e) {
            console.log(e)
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("Error al generar un pedido");
        }
    }



    static async patchOrder2(id: number, request: Order): Promise<Response<Order>> {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + "/api/orders/" + id, request, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            const order = JsonTool.jsonConvert.deserializeObject(response.data, Order)
            getModule(SnackbarModule).makeToast("Pedido editado")
            return Promise.resolve({ result: order })
        } catch (e) { return Promise.reject(e) }
    }

    static async patchOrder(component: Vue, order: Order) {
        // @ts-ignore
        component.loading = true
        try {
            await component.axios.patch(ConstantTool.BASE_URL + "/api/orders/" + order.id!, order, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("Editado un pedido")
        } catch (err) {
            console.log(err)
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("Error al editar un pedido");
        }
    }

    static async sendOrder2(id: number): Promise<Response<Order>> {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + "/api/orders/" + id + "/send", null, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const order = JsonTool.jsonConvert.deserializeObject(response.data, Order)
            getModule(SnackbarModule).makeToast("Pedido enviado con exito")
            return Promise.resolve({ result: order })
        } catch (e) { return Promise.reject(e) }
    }
    
    static async sendOrder(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            await component.axios.patch(ConstantTool.BASE_URL + "/api/orders/" + id + "/send", null, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("Pedido enviado con exito!")
        } catch (err) {
            console.log(err)
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("Error al enviar el pedido")
        }
    }

    static async cloneOrder(component: Vue, order: Order) {
        // @ts-ignore
        component.loading = true
        order.id = undefined
        try {
            await component.axios.post(ConstantTool.BASE_URL + "/api/clients/" + order.client!.id! + "/orders", order, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("Pedido clonado con exito!")
            // @ts-ignore
            component.refresh()
        } catch (err) {
            console.log(err)
            // @ts-ignore
            component.loading = true
            getModule(SnackbarModule).makeToast("Error al clonar el pedido")
        }
    }

    static async draftOrder2(request: Order): Promise<Response<Order>> {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + "/api/orders/draft", request, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            const order = JsonTool.jsonConvert.deserializeObject(response.data, Order)
            return Promise.resolve({ result: order })
        } catch (e) {
            const error = e as AxiosError
            if (error.response?.data.message == "XGEST_BLOCKED_PRODUCT") {
                getModule(SnackbarModule).makeToast("Se ha detectado un producto bloqueado en su consulta.")
            }
            return Promise.reject(e)
        }
    }

    static async draftOrder(component: Vue, order: Order) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.patch(ConstantTool.BASE_URL + "/api/orders/draft", order, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            const orderResponse = JsonTool.jsonConvert.deserializeObject(response.data, Order)
            // @ts-ignore
            component.loading = false
            // @ts-ignore
            component.order.id = orderResponse.id
        } catch (e) {
            console.log(e)
            // @ts-ignore
            component.loading = false
        }
    }

    static async getOrder2(id: number): Promise<Response<Order>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/public/orders/" + id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            const order = JsonTool.jsonConvert.deserializeObject(response.data, Order)
            return Promise.resolve({ result: order })
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se pudo obtener el pedido")
            return Promise.reject(e)
        }
    }

    static async getOrder(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/public/orders/" + id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            });
            // @ts-ignore
            component.order = JsonTool.jsonConvert.deserializeObject(response.data, Order)

            // @ts-ignore
            component.loading = false
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se pudo obtener el pedido")
        }
    }

    static async deleteOrder(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.delete(ConstantTool.BASE_URL + "/api/orders/" + id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.loading = false
            // @ts-ignore
            component.refresh()
            getModule(SnackbarModule).makeToast("Has eliminado el pedido");
        } catch (e) {
            console.log(e)
            getModule(SnackbarModule).makeToast("Error al eliminar un pedido")
        }
    }

    static async getOrders(
        component: Vue, orders: Order[], page: number, size: number, search: string | null,
        clientId: number | null, companyId: number | null, start: string | null, end: string | null, userId: string | null
    ) {
        (<any>component).loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/orders", {
                params: { page, size, search, clientId, companyId, start, end, userId },
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            orders.splice(0, orders.length)
            JsonTool.jsonConvert.deserializeArray(response.data, Order).forEach(p => orders.push(p))
            // @ts-ignore
            component.totalItems = Number(response.headers["x-total-count"]);
            (<any>component).loading = false
        } catch(e) {
            console.log(e)
            getModule(SnackbarModule).makeToast("No se han podido obtener los pedidos");
            (<any>component).loading = false
        }
    }

    static async getOrderPdf(component: Vue, order: Order) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/orders/" + order.id + "/pdf", {
                responseType: "blob",
                headers: {Authorization: getModule(SessionModule).session.token}
            })

            window.open(URL.createObjectURL(response.data));

            // @ts-ignore
            component.loading = false;
        } catch (e) {
            console.log(e)
            getModule(SnackbarModule).makeToast("Ha ocurrido un error generando el documento.");
            // @ts-ignore
            component.loading = false;
        }
    }

}